#rodape-fale-conosco {
  background: url(../img/rodape-fale-conosco.jpg) 50% no-repeat;
  background-size: cover;
  color: $cor-branco;
}

.rodape-fale-conosco-titulos {
  float: left;
  margin: 67px 0 0 8.73%;
  width: 36.61%;
  @media (max-width: 1140px) {
    font-size: em(15);
  }
  @media (max-width: 1060px) {
    font-size: em(14);
  }
  @media (max-width: 800px) {
    margin-left: 0;
    width: 45.34%;
  }
  @media (max-width: 640px) {
    @include stack();
  }
}

.rodape-fale-conosco-titulo {
  font-size: em(38);
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 4px 4px 6px $cor-preto;
}

.rodape-fale-conosco-texto {
  font-size: em(22);
  font-weight: 600;
  line-height: 1.18;
  margin-top: 15px;
  position: relative;
  text-shadow: 4px 4px 6px $cor-preto;
  &::after {
    @extend .sprite;
    @extend .rodape-fale-conosco-seta;
    content: '';
    position: absolute;
    right: -14px;
    top: calc(100% + 62px);
    @media (max-width: 910px) {
      top: calc(100% + 20px);
    }
    @media (max-width: 640px) {
      content: none;
    }
  }
}

.rodape-fale-conosco-container {
  float: right;
  margin: 71px 6.78% 63px 0;
  width: 42.2%;
  [type=text],
  textarea {
    @include stack();
    background: transparent;
    border: solid 1px rgba($cor-branco, .61);
    border-radius: 3px;
    box-sizing: border-box;
    color: $cor-branco;
    font-size: em(18);
    font-weight: 600;
    line-height: 1.39;
    outline: none;
    padding: 6px 15px;
    &:not(:first-child) {
      margin-top: 6px;
    }
    @include placeholder {
      color: $cor-branco;
    }
    &:focus {
      @include placeholder {
        color: rgba($cor-branco, .2);
      }
    }
    &.error {
      border-color: $cor-vermelho-claro;
    }
  }
  [type=text] {
    height: 39px;
  }
  textarea {
    height: 93px;
    resize: vertical;
  }
  [type=submit] {
    background: $cor-cinza-escuro;
    float: right;
    border: none;
    border-radius: 21.5px;
    color: $cor-branco;
    cursor: pointer;
    font-size: em(18);
    font-weight: 600;
    height: 43px;
    margin-top: 37px;
    min-width: 42.17%;
    transition: opacity .4s;
    will-change: opacity;
    &:focus,
    &:hover {
      opacity: .7;
    }
  }
  label.error {
    @include stack();
    background: rgba($cor-vermelho-claro, .6);
    box-sizing: border-box;
    border-radius: 5px;
    color: $cor-branco;
    font-size: em(13);
    font-weight: 500;
    margin-top: 5px;
    padding: 5px;
  }
  @media (max-width: 640px) {
    @include stack();
    display: inline-block;
    margin: 40px 0;
  }
}

.rodape-seguradoras-titulo {
  @extend .titulo;
  margin-top: 65px;
  text-align: left;
  text-indent: 3px;
  #geral:not(.index) & {
    margin-top: 0;
  }
}
.rodape-seguradoras-lista {
  @include center(1030px);
  margin-bottom: 64px;
  margin-top: 28px;
  .fs-carousel-wrapper {
    width: calc(100% - 90px);
    margin: 0 45px;
    z-index: 2;
  }
  .fs-carousel-controls {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .fs-carousel-control {
    @extend .sprite;
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-indent: 200vw;
    transition: opacity .4s;
    will-change: opacity;
    &.fs-carousel-control_previous {
      @extend .seta-esquerda;
      float: left;
    }
    &.fs-carousel-control_next {
      @extend .seta-direita;
      float: right;
    }
    &:focus,
    &:hover {
      opacity: .7;
    }
  }
  .fs-carousel-canister {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.rodape-seguradoras-item {
  text-align: center;
}
.rodape-seguradoras-logo {
  height: auto;
  max-width: 100%;
  width: auto;
}

#rodape {
  background: $cor-cinza-escuro;
  color: $cor-amarelo-2;
}

.rodape-menu {
  @include center(980px);
  border-bottom: solid 1px $cor-branco;
  display: flex;
  @media (max-width: 770px) {
    font-size: em(15);
  }
  @media (max-width: 620px) {
    flex-wrap: wrap;
  }
}
.rodape-menu-item {
  flex: 1 0 auto;
  @media (max-width: 620px) {
    flex-basis: 25%;
  }
  @media (max-width: 420px) {
    flex-basis: 50%;
  }
}
.rodape-menu-link {
  color: $cor-branco;
  display: block;
  font-size: em(15);
  font-weight: 700;
  line-height: 1.4;
  padding: 26px 0 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:focus,
  &:hover {
    background: rgba($cor-branco, .1);
    color: $cor-amarelo-2;
  }
  @media (max-width: 770px) {
    text-transform: none;
  }
  @media (max-width: 680px) {
    font-weight: 400;
  }
  @media (max-width: 420px) {
    padding: 15px 0;
  }
}


/* CONTATOS */
.rodape-contatos {
  @include stack();
  color: $cor-amarelo-2;
  display: inline-block;
  margin-top: 58px;
  text-align: center;
  .separador {
    display: inline-block;
    margin: 0 7px;
  }
}

.rodape-telefones {
  display: inline-block;
  font-size: em(19);
  line-height: 1.32;
  .fa {
    font-size: em(16, 19);
    line-height: 1.38;
    margin: 2px 12px 0 0;
  }
  @media (max-width: 620px) {
    @include stack();
    margin: 5px 0;
  }
}
.rodape-telefone {
  color: $cor-amarelo-2;
  display: inline-block;
  text-decoration: none;
  span {
    font-size: em(18, 30);
  }
}

.rodape-emails {
  display: inline-block;
  font-size: em(15);
  line-height: 1.4;
  margin-left: 28px;
  .fa {
    margin-right: 20px;
  }
  @media (max-width: 620px) {
    @include stack();
    margin: 5px 0;
  }
}

.rodape-email {
  color: $cor-amarelo-2;
  display: inline-block;
  text-decoration: none;
}

/* Address */
.rodape-endereco {
  @include stack();
  color: $cor-amarelo-2;
  display: inline-block;
  font-size: em(17);
  line-height: 1.41;
  margin-top: 31px;
  text-align: center;
  @media (max-width: 560px) {
    font-size: em(15);
    margin-top: 25px;
  }
}

.copyright {
  @include center(980px);
  border-top: solid 1px $cor-cinza-medio-5;
  color: $cor-branco;
  font: #{em(10)}/1.7 'Verdana', sans-serif;
  margin-top: 51px;
  padding: 8px 0;
  text-align: center;
}

.selos {
  @include stack();
  display: inline-block;
  padding: 5px 0 10px;
  text-align: center;
}

.selo-vn {
  background: url(http://www.vianetbrasil.com.br/selo_claro.png);
  display: inline-block;
  height: 17px;
  margin: 0 10px;
  vertical-align: middle;
  width: 100px;
}
