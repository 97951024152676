/* Cores */

$cor-branco: #fff;
$cor-preto: #000;

$cor-cinza-clarissimo: #eee;
$cor-cinza-claro: #ccc;
$cor-cinza-claro-2: #ddd;
$cor-cinza-claro-3: #999;
$cor-cinza-medio: #525252;
$cor-cinza-medio-2: #545454;
$cor-cinza-medio-3: #555;
$cor-cinza-medio-4: #535353;
$cor-cinza-medio-5: #595959;
$cor-cinza-medio-6: #666;
$cor-cinza-escuro: #444;

$cor-amarelo: #FAC016;
$cor-amarelo-2: #FFBA00;
$cor-amarelo-3: #FCBF17;

$cor-marrom: #58430C;

$cor-vermelho-claro: #F2666D;
