:not(#geral).servicos {
  @include stack();
  display: inline-block;
  margin-left: -13px;
  max-width: calc(100vw - 40px);
  width: calc(100% + 13px);
  @media (max-width: 1058px) {
    max-width: initial;
    width: 100%;
  }
  @media (max-width: 680px) {
    @include center(80%);
  }
  @media (max-width: 440px) {
    @include stack();
    max-width: 100%;
  }
}
.servicos-item {
  @include column(4/10, $gutter: 2.07, $cycle: 2);
  &:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 28px;
  }
  @media (max-width: 1058px) {
    @include column(1/2, $gutter: 3, $cycle: 2);
  }
  @media (max-width: 680px) {
    @include stack();
    &:not(:nth-child(1)) {
      margin-top: 28px;
    }
  }
}
.servicos-link {
  @include stack();
  display: inline-block;
  text-decoration: none;
}
.servicos-icone {
  @extend .home-servicos-icone;
  border-width: 2px;
  bottom: initial;
  float: left;
  height: 99px;
  left: initial;
  margin: 0 36px 0 -19px;
  padding: 0;
  position: relative;
  transform: none;
  width: 99px;
  &::after {
    border-radius: 49%;
    .servicos-link:hover &,
    .servicos-link:focus & {
      opacity: 0;
      will-change: opacity;
    }
  }
  .servicos-link:hover &,
  .servicos-link:focus & {
    background: $cor-branco;
  }
  @media (max-width: 1058px) {
    margin-left: 0;
  }
  @media (max-width: 890px) {
    height: 80px;
    margin-right: 25px;
    width: 80px;
  }
  @media (max-width: 380px) {
    font-size: em(20);
    height: 60px;
    margin-right: 15px;
    width: 60px;
  }
}
.servicos-nome {
  color: $cor-marrom;
  font-size: em(22);
  font-weight: 700;
  letter-spacing: -.3px;
  line-height: 99px;
  text-transform: uppercase;
  @media (max-width: 890px) {
    font-size: em(20);
    font-weight: 400;
  }
  @media (max-width: 890px) {
    font-size: em(17);
    line-height: 80px;
  }
  @media (max-width: 380px) {
    font-size: em(15);
    line-height: 60px;
  }
}
