.home-servicos {
  @include center(980px);
  @include cf();
}
.home-servicos-item {
  @include column(1/4, $gutter: 2.04, $cycle: 4);
  margin-bottom: 70px;
  margin-top: 37px;
  @media (max-width: 768px) {
    @include column(1/3, $gutter: 3, $cycle: 3)
  }
  @media (max-width: 580px) {
    @include column(1/2, $gutter: 4, $cycle: 2)
  }
  @media (max-width: 380px) {
    font-size: em(14);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.home-servicos-link {
  @include stack();
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.home-servicos-nome {
  background: $cor-amarelo-2;
  color: $cor-branco;
  display: inline-block;
  float: left;
  font-size: em(15);
  font-weight: bold;
  line-height: 21px;
  padding: 18px 0 19px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.home-servicos-imagem-container {
  @include stack();
  padding-top: 140.43%;
  position: relative;
}
.home-servicos-imagem {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.home-servicos-descricao {
  @include stack();
  @include linear-gradient(#111, transparent);
  background-color: transparent !important;
  left: 0;
  padding-top: 74.35%;
  position: absolute;
  top: 58px;
}
.home-servicos-descricao-texto {
  color: $cor-branco;
  font-size: em(15);
  font-weight: 600;
  left: 50%;
  line-height: 21px;
  max-height: 63px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 22px;
  transform: translateX(-50%);
  width: calc(100% - 12px);
  @media (max-width: 580px) {
    max-height: 84px;
  }
}
.home-servicos-icone {
  background: $cor-amarelo-2;
  border: solid 1px $cor-amarelo-2;
  border-radius: 50%;
  box-sizing: border-box;
  bottom: 0;
  color: $cor-marrom;
  font-size: em(40);
  left: 50%;
  padding-top: 43.04%;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, 50%);
  width: 43.04%;
  &::before {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  &::after {
    background: $cor-amarelo-2;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .4s;
    width: 100%;
    .home-servicos-link:hover &,
    .home-servicos-link:focus & {
      opacity: 0;
      will-change: opacity;
    }
  }
  .home-servicos-item:nth-child(3) & {
    font-size: em(48);
  }
  .home-servicos-item:nth-child(4) & {
    font-size: em(45);
  }
  .home-servicos-link:hover &,
  .home-servicos-link:focus & {
    background: $cor-branco;
  }
}

.home-todos-os-servicos {
  @include center(280px);
  background: $cor-cinza-escuro;
  border-radius: 30px;
  color: $cor-branco;
  font-size: em(22);
  font-weight: 600;
  line-height: 61px;
  margin-top: 78px;
  margin-bottom: 91px;
  text-align: center;
  text-decoration: none;
  transition: opacity .4s;
  will-change: opacity;
  &:focus,
  &:hover {
    color: $cor-branco;
    opacity: .7;
  }
}
