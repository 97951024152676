.noticias {
  @include stack();
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px);
}
.noticias-item {
  border-bottom: solid 1px $cor-cinza-clarissimo;
  padding: 0 0 24px 10px;
  &:not(:nth-child(1)) {
    margin-top: 46px;
  }
}
.noticias-link {
  @include stack();
  display: inline-block;
  text-decoration: none;
}
.noticias-data {
  background: $cor-amarelo-2;
  color: $cor-marrom;
  display: inline-block;
  float: left;
  font-size: em(16);
  font-weight: 700;
  line-height: 1.38;
  margin-right: 26px;
  padding: 8px 0 6px;
  text-align: center;
  width: 120px;
  @media (max-width: 480px) {
    font-size: em(15);
    font-weight: 500;
    margin-right: 15px;
    width: 100px;
  }
}
.noticias-descricao {
  color: $cor-cinza-medio-6;
  font-size: em(15);
  line-height: 1.3;
}

.conteudoNoticia {
  @include stack();
  color: $cor-cinza-medio-6;
  display: inline-block;
  font-size: em(15);
  line-height: 1.4;
  margin-left: -13px !important;
  width: calc(100% + 13px);
  .noticia-titulo {
    color: $cor-cinza-medio-6;
    font-size: em(28);
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    text-transform: none;
  }
  .noticia-data {
    color: $cor-amarelo-2;
    font-size: em(15);
    line-height: 1.4;
    margin-top: 3px;
  }
  p {
    margin: 3em 0 0 0;
  }
  .foto {
    @include stack();
    margin: 25px 0 3em;
    &::before {
      overflow: hidden;
      padding-top: 27.24%;
      @media (max-width: 480px) {
        padding-top: 40%;
      }
      @media (max-width: 360px) {
        padding-top: 70%;
      }
    }
  }
}
