/*serrilhado a fonte*/

* {
  font-smoothing: antialiased;
}


/* RESET */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}


/* NORMALIZACAO PARA EDITOR */

.conteudoEditor {
  display: inline-block;
  width: 100%;

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  nav,
  section,
  summary {
    display: block
  }

  audio,
  canvas,
  video {
    display: inline-block;
    *display: inline;
    *zoom: 1
  }

  audio:not([controls]) {
    display: none;
    height: 0
  }

  [hidden] {
    display: none
  }

  button,
  input,
  select,
  textarea {
    font-family: sans-serif
  }

  a:focus {
    outline: thin dotted
  }

  a:hover,
  a:active {
    outline: 0
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0
  }

  h2 {
    font-size: 1.5em;
    margin: 0.83em 0
  }

  h3 {
    font-size: 1.17em;
    margin: 1em 0
  }

  h4 {
    font-size: 1em;
    margin: 1.33em 0
  }

  h5 {
    font-size: 0.83em;
    margin: 1.67em 0
  }

  h6 {
    font-size: 0.75em;
    margin: 2.33em 0
  }

  abbr[title] {
    border-bottom: 1px dotted
  }

  b,
  strong {
    font-weight: bold
  }

  blockquote {
    margin: 1em 40px
  }

  dfn {
    font-style: italic
  }

  mark {
    background: #ff0;
    color: #000
  }

  p,
  pre {
    margin: 1em 0;
    &:first-of-type {
      margin-top: 0;
    }
  }

  pre,
  code,
  kbd,
  samp {
    font-family: monospace, serif;
    _font-family: 'courier new', monospace;
    font-size: 1em
  }

  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
  }

  q {
    quotes: none
  }

  q:before,
  q:after {
    content: '';
    content: none
  }

  small {
    font-size: 75%
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
  }

  sup {
    top: -0.5em
  }

  sub {
    bottom: -0.25em
  }

  dl,
  menu,
  ol,
  ul {
    margin: 1em 0
  }

  dd {
    margin: 0 0 0 40px
  }

  menu,
  ol,
  ul {
    padding: 0 0 0 40px
  }

  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none
  }

  img {
    border: 0;
    -ms-interpolation-mode: bicubic
  }

  svg:not(:root) {
    overflow: hidden
  }

  figure {
    margin: 0
  }

  form {
    margin: 0
  }

  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em
  }

  legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle
  }

  button,
  input {
    line-height: normal
  }

  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible
  }

  button[disabled],
  input[disabled] {
    cursor: default
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0
  }

  textarea {
    overflow: auto;
    vertical-align: top
  }

  table {
    border-collapse: collapse;
    border-spacing: 0
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ul:after,
  ul:after,
  li:after {
    content: "";
    display: inline;
  }

  ul ul {
    list-style: circle;
  }

  ol ol {
    list-style: upper-roman;
  }

  em {
    font-style: italic;
  }

}
