/* TOPO */

#topo {
  @include stack();
  background: rgba($cor-branco, .9);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 2;
  @media (max-width: 950px) {
    #geral.index & {
      position: static;
    }
  }
}

.logo-container {
  float: left;
  margin-top: 18px;
  @media (max-width: 460px) {
    margin-top: 10px;
  }
}

.logo {
  background: url(../img/logo.svg) no-repeat 50%;
  background-size: contain;
}

.logo a,
.logo {
  display: inline-block;
  height: 109px;
  width: 125px;
  @media (max-width: 460px) {
    height: 70px;
    width: 80px;
  }
}

/* CONTATOS */
.topo-contatos {
  display: inline-block;
  float: right;
  margin: 39px 17px 0 0;
  .separador {
    display: inline-block;
    margin: 0 7px;
    @media (max-width: 580px) {
      display: none;
    }
    @media (max-width: 460px) {
      display: inline-block;
      max-width: 0 4px
    }
  }
  @media (max-width: 650px) {
    margin: 20px 0 10px;
  }
  @media (max-width: 580px) {
    margin: 10px 0;
  }
  @media (max-width: 460px) {
    @include stack();
    display: inline-block;
    margin-bottom: 10px;
  }
}
.topo-telefones {
  clear: right;
  color: $cor-cinza-medio;
  display: inline-block;
  float: right;
  font-size: em(30);
  line-height: 1.27;
  margin-right: 2px;
  .fa {
    color: $cor-amarelo;
    font-size: em(16, 30);
    line-height: 1.38;
    margin: 12px 22px 0 0;
    @media (max-width: 460px) {
      margin-right: 10px;
    }
  }
  @media (max-width: 580px) {
    font-size: em(26);
    .fa {
      font-size: em(16, 26);
    }
  }
  @media (max-width: 380px) {
    font-size: em(21);
    .fa {
      font-size: em(16, 21);
    }
  }
}
.topo-telefone {
  color: $cor-cinza-medio;
  display: inline-block;
  text-decoration: none;
  span {
    font-size: em(18, 30);
  }
  @media (max-width: 580px) {
    clear: right;
    float: right;
  }
  @media (max-width: 460px) {
    clear: none;
    float: none;
  }
}

.topo-emails {
  clear: right;
  color: $cor-cinza-medio-2;
  display: inline-block;
  float: right;
  font-size: em(15);
  line-height: 1.4;
  margin-top: 7px;
  .fa {
    color: $cor-amarelo;
    margin-right: 20px;
    @media (max-width: 460px) {
      margin-right: 10px;
    }
  }
}
.topo-email {
  color: $cor-cinza-medio-2;
  display: inline-block;
  text-decoration: none;
}

#menuToggler {
  display: none;
  &:checked {
    ~ .menuToggler {
      color: $cor-branco;
      @media (max-width: 460px) {
        height: 0;
        position: static;
      }
      .fa::before {
        content: '\f05c';
        position: fixed;
        right: 15px;
        text-align: center;
        top: 15px;
        width: 1.01em;
        z-index: 10;
      }
    }
    ~ .topo-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}
.menuToggler {
  clear: right;
  color: $cor-amarelo;
  cursor: pointer;
  display: none;
  float: right;
  font-size: em(32);
  height: 35px;
  margin: 10px 0;
  z-index: 8;
  @media (max-width: 650px) {
    display: inline-block;
  }
  @media (max-width: 460px) {
    position: absolute;
    margin: 0;
    right: 20px;
    top: 20px;
  }
}

.topo-menu {
  border-top: solid 1px $cor-cinza-claro;
  display: flex;
  float: left;
  margin-top: 23px;
  width: 100%;
  @media (max-width: 650px) {
    background: rgba(darken($cor-amarelo, 10%), .7);
    border: none;
    bottom: 0;
    flex-direction: column;
    left: 0;
    margin: 0;
    overflow: auto;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .4s, visibility .4s;
    visibility: hidden;
    will-change: opacity;
    z-index: 9;
  }
}
.topo-menu-item {
  flex: 1 0 auto;
  @media (max-width: 650px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.topo-menu-link {
  color: $cor-cinza-medio-3;
  display: block;
  font-size: em(16);
  font-weight: 700;
  line-height: 1.38;
  padding: 9px 0 7px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:focus,
  &:hover {
    background: $cor-cinza-claro;
    color: $cor-branco;
  }
  @media (max-width: 860px) {
    font-size: em(15);
    font-weight: 400;
    text-transform: none;
  }
  @media (max-width: 650px) {
    color: $cor-branco;
    flex: 1 0 auto;
    font-size: em(24);
    line-height: 2;
    padding: 0;
    text-shadow: 0 0 3px rgba($cor-preto, .3);
  }
}

/* Banner */
.banner {
  height: 770px;
  .fs-carousel-item {
    opacity: 0;
    transition: .4s;
    visibility: hidden;
    &.fs-carousel-visible {
      opacity: 1;
      visibility: visible;
    }
  }
  li:not(:first-child) {
    visibility: hidden;
  }
  a {
    display: inline-block;
    float: left;
    width: 100%;
    img {
      float: left;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
    &[href=""] {
      cursor: default;
    }
  }
  @media (max-width: 1160px) {
    height: auto;
    a {
      img {
        height: auto;
        width: 160%;
      }
    }
  }
  @media (max-width: 520px) {
    overflow: initial !important;
  }
}
.banner-interno {
  height: 320px;
  position: relative;
}
.banner-interno-imagem {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

/* BANNER ATENDIMENTO 24 HORAS */
.atendimento-24-horas {
  background: $cor-cinza-escuro;
  color: $cor-branco;
  font-size: em(68);
  height: 129px;
  line-height: 1.15;
  .wrapper {
    position: relative;
  }
  .sprite {
    @include shift(4/12, $gutter: 1.69);
    position: absolute;
    top: 0;
    transform: translateX(3px);
    z-index: 1;
    @media (max-width: 540px) {
      @include unshift();
      position: absolute;
    }
  }
  @media (max-width: 540px) {
    font-size: em(40);
  }
}
.atendimento-24-horas-frase {
  @include shift(5/12, $gutter: 1.69);
  display: inline-block;
  margin-top: 10px;
  text-transform: uppercase;
  transform: translateX(25px);
  @media (max-width: 540px) {
    @include unshift();
    margin: 30px 0 0 20%;
  }
}
.atendimento-24-horas-frase-linha-1 {
  clear: left;
  display: inline-block;
  float: left;
  font-size: em(40, 68);
  line-height: 1.2;
}
.atendimento-24-horas-frase-linha-2 {
  clear: left;
  display: inline-block;
  float: left;
  margin-top: -10px;
  text-transform: none;
}
.atendimento-24-horas-numero {
  color: $cor-amarelo-2;
}
