/* COR PRIMARIA */

a {
  color: $cor-amarelo;
  &:focus,
  &:hover {
    color: darken($cor-amarelo, 10%);
  }
}

