/* ERRO PARA IE7 OU INFERIOR */

.ieError {
  position: absolute;
  height: 120px;
  *width: 100%;
  text-align: center;
  border-bottom: 4px solid #ccc;
  background: #fff;
  font: 14px/1.7 Tahoma, Geneva, sans-serif;
  display: none
}

.ieError a.ieClose {
  display: inline-block;
  position: absolute;
  top: -150px;
  _top: 10px;
  right: 10px;
  text-decoration: none;
  font: 14px/25 "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: #333;
  cursor: pointer;
  height: 25px
}

.ieError a.ieClose span {
  display: inline-block;
  background: #C30;
  color: #fff;
  font: 20px/25px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  width: 25px;
  text-align: center;
  margin: 0 0 0 5px
}

.ieError a.ieClose:hover span {
  background: #333
}

.ieError .ieWrapper {
  width: 750px;
  text-align: left;
  margin: 0 auto;
  background: url(../img/ie-exclamacao.jpg) 0 20px no-repeat;
  padding: 20px 0 0 0
}

.ieError h2,
.ieError p {
  margin-left: 100px
}

.ieError h2 {
  font-size: 15px;
  color: #C30;
  display: inline-block
}

.ieError p {
  display: inline;
  clear: left;
  float: left;
  width: 350px
}

.ieError ul {
  display: inline-block;
  float: right;
  width: 240px;
  margin: -30px 0 0 0
}

.ieError ul li {
  float: left
}

.ieError ul a {
  display: inline-block;
  color: #09C;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  width: 80px;
  cursor: pointer
}

.ieError ul a:hover {
  color: #C30
}

.ieError ul a span {
  display: inline-block;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat
}

.ieError a span.nome {
  width: 80px
}

.ieError a .ieNavegadoresChrome {
  background-image: url(../img/ie-navegador-chrome.jpg);
  height: 50px
}

.ieError a .ieNavegadoresFirefox {
  background-image: url(../img/ie-navegador-firefox.jpg);
  height: 50px
}

.ieError a .ieNavegadoresIe {
  background-image: url(../img/ie-navegador-ie.jpg);
  height: 50px
}

