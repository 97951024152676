@charset "UTF-8";
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper-pp:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

.fa-reddit-alien:before {
  content: ""; }

.fa-edge:before {
  content: ""; }

.fa-credit-card-alt:before {
  content: ""; }

.fa-codiepie:before {
  content: ""; }

.fa-modx:before {
  content: ""; }

.fa-fort-awesome:before {
  content: ""; }

.fa-usb:before {
  content: ""; }

.fa-product-hunt:before {
  content: ""; }

.fa-mixcloud:before {
  content: ""; }

.fa-scribd:before {
  content: ""; }

.fa-pause-circle:before {
  content: ""; }

.fa-pause-circle-o:before {
  content: ""; }

.fa-stop-circle:before {
  content: ""; }

.fa-stop-circle-o:before {
  content: ""; }

.fa-shopping-bag:before {
  content: ""; }

.fa-shopping-basket:before {
  content: ""; }

.fa-hashtag:before {
  content: ""; }

.fa-bluetooth:before {
  content: ""; }

.fa-bluetooth-b:before {
  content: ""; }

.fa-percent:before {
  content: ""; }

.fa-gitlab:before {
  content: ""; }

.fa-wpbeginner:before {
  content: ""; }

.fa-wpforms:before {
  content: ""; }

.fa-envira:before {
  content: ""; }

.fa-universal-access:before {
  content: ""; }

.fa-wheelchair-alt:before {
  content: ""; }

.fa-question-circle-o:before {
  content: ""; }

.fa-blind:before {
  content: ""; }

.fa-audio-description:before {
  content: ""; }

.fa-volume-control-phone:before {
  content: ""; }

.fa-braille:before {
  content: ""; }

.fa-assistive-listening-systems:before {
  content: ""; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

.fa-glide:before {
  content: ""; }

.fa-glide-g:before {
  content: ""; }

.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

.fa-low-vision:before {
  content: ""; }

.fa-viadeo:before {
  content: ""; }

.fa-viadeo-square:before {
  content: ""; }

.fa-snapchat:before {
  content: ""; }

.fa-snapchat-ghost:before {
  content: ""; }

.fa-snapchat-square:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-first-order:before {
  content: ""; }

.fa-yoast:before {
  content: ""; }

.fa-themeisle:before {
  content: ""; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }

.lg-actions .lg-next:before {
  content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }

.lg-actions .lg-prev:after {
  content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear; }

.lg-toolbar .lg-icon:hover {
  color: #FFF; }

.lg-toolbar .lg-close:after {
  content: "\e070"; }

.lg-toolbar .lg-download:after {
  content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold; }

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707; }

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }

.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff"; }

.lg-outer .lg-toogle-thumb:hover {
  color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0; }

.lg-progress-bar.lg-start .lg-progress {
  width: 100%; }

.lg-show-autoplay .lg-progress-bar {
  opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }

#lg-actual-size:after {
  content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }

#lg-zoom-out:after {
  content: "\e312"; }

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"; }

.group {
  *zoom: 1; }

.group:before, .group:after {
  display: table;
  content: "";
  line-height: 0; }

.group:after {
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer .lg-item {
  background: url("../img/loading.gif") no-repeat scroll center center transparent;
  display: none !important; }

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important; }

.lg-outer.lg-css .lg-current {
  display: inline-block !important; }

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-complete {
  background-image: none; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html {
  display: none; }

.lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }

.lg-backdrop.in {
  opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/*# sourceMappingURL=lightgallery.css.map */
.lg-css3.lg-zoom-in .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3); }

.lg-css3.lg-zoom-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3); }

.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7); }

.lg-css3.lg-zoom-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7); }

.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0; }

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1); }

.lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9); }

.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-scale-up .lg-item {
  opacity: 0; }

.lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }

.lg-css3.lg-scale-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }

.lg-css3.lg-scale-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-scale-up .lg-item.lg-prev-slide, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-circular .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-circular .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }

.lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }

.lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }

.lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }

.lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0); }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0); }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg); }

.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg); }

.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg); }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg); }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg); }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg); }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg); }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg); }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px); }

.lg-css3.lg-slide-skew .lg-item.lg-next-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px); }

.lg-css3.lg-slide-skew .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px); }

.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
  transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px); }

.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px); }

.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px); }

.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px); }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
  transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px); }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, -100%, 0px); }

.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(60deg, 0deg) translate3d(0, 100%, 0px); }

.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px); }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
  transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px); }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, -100%, 0px); }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, 20deg) translate3d(0, 100%, 0px); }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, -100%, 0px); }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
  transform: skew(0deg, -20deg) translate3d(0, 100%, 0px); }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop .lg-item {
  opacity: 0; }

.lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-lollipop .lg-item.lg-next-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5); }

.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-lollipop .lg-item.lg-prev-slide, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5); }

.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate .lg-item {
  opacity: 0; }

.lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.lg-css3.lg-rotate .lg-item.lg-next-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.lg-css3.lg-rotate .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

.lg-css3.lg-rotate .lg-item.lg-prev-slide, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 1; }

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-tube .lg-item {
  opacity: 0; }

.lg-css3.lg-tube .lg-item.lg-prev-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0); }

.lg-css3.lg-tube .lg-item.lg-next-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(100%, 0, 0); }

.lg-css3.lg-tube .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-tube .lg-item.lg-prev-slide, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/*# sourceMappingURL=lg-transitions.css.map */
@font-face {
  font-family: 'lg';
  src: url("fonts/lg.woff2?n1z373") format("woff2"), url("fonts/lg.woff?n1z373") format("woff");
  font-weight: normal;
  font-style: normal; }

/*! formstone v1.2.1 [carousel.css] 2016-08-02 | GPL-3.0 License | formstone.it */
.fs-carousel.fs-carousel-enabled {
  width: 100%;
  position: relative; }

.fs-carousel.fs-carousel-enabled:after {
  clear: both;
  content: '';
  display: table; }

.fs-carousel, .fs-carousel *, .fs-carousel :after, .fs-carousel :before, .fs-carousel:after, .fs-carousel:before {
  box-sizing: border-box;
  -webkit-transition: none;
  transition: none;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.fs-carousel-enabled.fs-carousel-contained, .fs-carousel-enabled.fs-carousel-contained .fs-carousel-container, .fs-carousel-enabled.fs-carousel-contained .fs-carousel-viewport, .fs-carousel-enabled.fs-carousel-contained .fs-carousel-wrapper {
  overflow: hidden; }

.fs-carousel-enabled .fs-carousel-viewport {
  position: relative; }

.fs-carousel-enabled .fs-carousel-viewport:after {
  clear: both;
  content: '';
  display: table; }

.fs-carousel-enabled .fs-carousel-wrapper {
  position: relative; }

.fs-carousel-enabled .fs-carousel-wrapper:after {
  clear: both;
  content: '';
  display: table; }

.fs-carousel-enabled .fs-carousel-container {
  position: relative; }

.fs-carousel-enabled .fs-carousel-container:after {
  clear: both;
  content: '';
  display: table; }

.fs-carousel-enabled .fs-carousel-canister {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  overflow: hidden;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease, -webkit-transform .5s ease;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fs-carousel-enabled.fs-carousel-auto_height .fs-carousel-canister {
  -webkit-transition: height .5s ease,-webkit-transform .5s ease;
  transition: height .5s ease,-webkit-transform .5s ease;
  transition: height .5s ease,transform .5s ease;
  transition: height .5s ease,transform .5s ease,-webkit-transform .5s ease; }

.fs-carousel-enabled .fs-carousel-item {
  min-height: 1px;
  display: block;
  float: left; }

.fs-carousel-enabled .fs-carousel-item img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fs-carousel-single .fs-carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.fs-carousel-single .fs-carousel-item.fs-carousel-visible {
  position: relative;
  z-index: 2; }

.fs-carousel-controls {
  display: none; }

.fs-carousel-enabled .fs-carousel-controls {
  width: 100%;
  margin: 0;
  padding: 0; }

.fs-carousel-controls_custom.fs-carousel-visible, .fs-carousel-enabled .fs-carousel-controls.fs-carousel-visible {
  display: block; }

.fs-carousel-enabled .fs-carousel-control {
  border: none;
  cursor: pointer;
  padding: 0; }

.fs-carousel-pagination {
  display: none; }

.fs-carousel-enabled .fs-carousel-pagination {
  width: 100%; }

.fs-carousel-enabled .fs-carousel-pagination.fs-carousel-visible {
  display: block; }

.fs-carousel-enabled .fs-carousel-page {
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0; }

.fs-carousel-enabled.fs-carousel-rtl {
  direction: rtl; }

.fs-carousel-enabled.fs-carousel-rtl .fs-carousel-item {
  float: right; }

/* Cores */
/*serrilhado a fonte*/
* {
  font-smoothing: antialiased; }

/* RESET */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* NORMALIZACAO PARA EDITOR */
.conteudoEditor {
  display: inline-block;
  width: 100%; }
  .conteudoEditor article,
  .conteudoEditor aside,
  .conteudoEditor details,
  .conteudoEditor figcaption,
  .conteudoEditor figure,
  .conteudoEditor footer,
  .conteudoEditor header,
  .conteudoEditor hgroup,
  .conteudoEditor nav,
  .conteudoEditor section,
  .conteudoEditor summary {
    display: block; }
  .conteudoEditor audio,
  .conteudoEditor canvas,
  .conteudoEditor video {
    display: inline-block;
    *display: inline;
    *zoom: 1; }
  .conteudoEditor audio:not([controls]) {
    display: none;
    height: 0; }
  .conteudoEditor [hidden] {
    display: none; }
  .conteudoEditor button,
  .conteudoEditor input,
  .conteudoEditor select,
  .conteudoEditor textarea {
    font-family: sans-serif; }
  .conteudoEditor a:focus {
    outline: thin dotted; }
  .conteudoEditor a:hover,
  .conteudoEditor a:active {
    outline: 0; }
  .conteudoEditor h1 {
    font-size: 2em;
    margin: 0.67em 0; }
  .conteudoEditor h2 {
    font-size: 1.5em;
    margin: 0.83em 0; }
  .conteudoEditor h3 {
    font-size: 1.17em;
    margin: 1em 0; }
  .conteudoEditor h4 {
    font-size: 1em;
    margin: 1.33em 0; }
  .conteudoEditor h5 {
    font-size: 0.83em;
    margin: 1.67em 0; }
  .conteudoEditor h6 {
    font-size: 0.75em;
    margin: 2.33em 0; }
  .conteudoEditor abbr[title] {
    border-bottom: 1px dotted; }
  .conteudoEditor b,
  .conteudoEditor strong {
    font-weight: bold; }
  .conteudoEditor blockquote {
    margin: 1em 40px; }
  .conteudoEditor dfn {
    font-style: italic; }
  .conteudoEditor mark {
    background: #ff0;
    color: #000; }
  .conteudoEditor p,
  .conteudoEditor pre {
    margin: 1em 0; }
    .conteudoEditor p:first-of-type,
    .conteudoEditor pre:first-of-type {
      margin-top: 0; }
  .conteudoEditor pre,
  .conteudoEditor code,
  .conteudoEditor kbd,
  .conteudoEditor samp {
    font-family: monospace, serif;
    _font-family: 'courier new', monospace;
    font-size: 1em; }
  .conteudoEditor pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word; }
  .conteudoEditor q {
    quotes: none; }
  .conteudoEditor q:before,
  .conteudoEditor q:after {
    content: '';
    content: none; }
  .conteudoEditor small {
    font-size: 75%; }
  .conteudoEditor sub,
  .conteudoEditor sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  .conteudoEditor sup {
    top: -0.5em; }
  .conteudoEditor sub {
    bottom: -0.25em; }
  .conteudoEditor dl,
  .conteudoEditor menu,
  .conteudoEditor ol,
  .conteudoEditor ul {
    margin: 1em 0; }
  .conteudoEditor dd {
    margin: 0 0 0 40px; }
  .conteudoEditor menu,
  .conteudoEditor ol,
  .conteudoEditor ul {
    padding: 0 0 0 40px; }
  .conteudoEditor nav ul,
  .conteudoEditor nav ol {
    list-style: none;
    list-style-image: none; }
  .conteudoEditor img {
    border: 0;
    -ms-interpolation-mode: bicubic; }
  .conteudoEditor svg:not(:root) {
    overflow: hidden; }
  .conteudoEditor figure {
    margin: 0; }
  .conteudoEditor form {
    margin: 0; }
  .conteudoEditor fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em; }
  .conteudoEditor legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px; }
  .conteudoEditor button,
  .conteudoEditor input,
  .conteudoEditor select,
  .conteudoEditor textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle; }
  .conteudoEditor button,
  .conteudoEditor input {
    line-height: normal; }
  .conteudoEditor button,
  .conteudoEditor input[type="button"],
  .conteudoEditor input[type="reset"],
  .conteudoEditor input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible; }
  .conteudoEditor button[disabled],
  .conteudoEditor input[disabled] {
    cursor: default; }
  .conteudoEditor input[type="checkbox"],
  .conteudoEditor input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px; }
  .conteudoEditor input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
  .conteudoEditor input[type="search"]::-webkit-search-decoration,
  .conteudoEditor input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }
  .conteudoEditor button::-moz-focus-inner,
  .conteudoEditor input::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .conteudoEditor textarea {
    overflow: auto;
    vertical-align: top; }
  .conteudoEditor table {
    border-collapse: collapse;
    border-spacing: 0; }
  .conteudoEditor ol {
    list-style: decimal; }
  .conteudoEditor ul {
    list-style: disc; }
  .conteudoEditor ul:after,
  .conteudoEditor ul:after,
  .conteudoEditor li:after {
    content: "";
    display: inline; }
  .conteudoEditor ul ul {
    list-style: circle; }
  .conteudoEditor ol ol {
    list-style: upper-roman; }
  .conteudoEditor em {
    font-style: italic; }

/* COR PRIMARIA */
a {
  color: #FAC016; }
  a:focus, a:hover {
    color: #d8a305; }

/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/* Título */
.titulo, .rodape-seguradoras-titulo {
  width: auto;
  max-width: 980px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  border-bottom: solid 2px #ddd;
  color: #000;
  font-size: 1.875em;
  line-height: 1.27;
  margin-top: 66px;
  padding-bottom: 16px;
  text-align: center;
  text-transform: uppercase; }
  .titulo span, .rodape-seguradoras-titulo span {
    color: #FCBF17;
    font-weight: 600; }

/* Caixa interna */
.conteudoInterna {
  width: auto;
  max-width: 980px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
  padding: 64px 0 137px 13px; }
  .conteudoInterna:empty {
    display: none; }
  @media (max-width: 1020px) {
    .conteudoInterna {
      *zoom: 1; }
      .conteudoInterna:before, .conteudoInterna:after {
        content: '';
        display: table; }
      .conteudoInterna:after {
        clear: both; } }

/* Área de editor */
.conteudoEditor {
  box-sizing: border-box;
  color: #535353;
  font-size: 1em;
  line-height: 1.56; }
  .conteudoEditor > :first-child {
    margin-top: 0; }
  .conteudoEditor > :last-child {
    margin-bottom: 0; }
  .conteudoEditor:empty {
    display: none; }
  .conteudoEditor h1, .conteudoEditor h2, .conteudoEditor h3, .conteudoEditor h4, .conteudoEditor h5, .conteudoEditor h6 {
    color: #58430C;
    font-weight: 700;
    margin-top: 1.5em;
    text-transform: uppercase; }
  .conteudoEditor h2 {
    font-size: 1.38em; }
  .conteudoEditor h3 {
    font-size: 1.25em; }
  .conteudoEditor h4 {
    font-size: 1.2em; }
  .conteudoEditor h5 {
    font-size: 1.1em; }
  .conteudoEditor h6 {
    font-size: 1em; }
  .conteudoEditor .foto {
    display: inline-block;
    float: left;
    margin: 0 2.07% 20px 0;
    position: relative;
    width: 39.30%; }
    .conteudoEditor .foto::before {
      content: '';
      display: block;
      padding-top: 57.27%; }
    .conteudoEditor .foto + * {
      margin-top: 0; }
    .conteudoEditor .foto a {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%; }
    .conteudoEditor .foto img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%; }
    @media (max-width: 480px) {
      .conteudoEditor .foto {
        float: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%; } }
  .conteudoEditor.fotoDireita .foto {
    float: right;
    margin: 0 0 20px 2.07%; }
  .conteudoEditor .foto span {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    color: #fff;
    display: none;
    font-size: 1em;
    left: 0;
    line-height: 2;
    position: absolute;
    text-align: center;
    width: 100%; }
  .conteudoEditor ul.fotos {
    clear: both;
    display: inline-block;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
    width: 100%; }
  .conteudoEditor ul.fotos li {
    float: left;
    clear: none;
    width: 22.75%;
    margin-left: 0;
    margin-right: 3%; }
    .conteudoEditor ul.fotos li:last-child {
      margin-right: 0; }
  .conteudoEditor.fotosBaixo {
    padding: 0 0 170px 0;
    position: relative; }
  .conteudoEditor.fotosBaixo ul.fotos {
    position: absolute;
    bottom: 0; }

/* ERRO PARA IE7 OU INFERIOR */
.ieError {
  position: absolute;
  height: 120px;
  *width: 100%;
  text-align: center;
  border-bottom: 4px solid #ccc;
  background: #fff;
  font: 14px/1.7 Tahoma, Geneva, sans-serif;
  display: none; }

.ieError a.ieClose {
  display: inline-block;
  position: absolute;
  top: -150px;
  _top: 10px;
  right: 10px;
  text-decoration: none;
  font: 14px/25 "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: #333;
  cursor: pointer;
  height: 25px; }

.ieError a.ieClose span {
  display: inline-block;
  background: #C30;
  color: #fff;
  font: 20px/25px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  width: 25px;
  text-align: center;
  margin: 0 0 0 5px; }

.ieError a.ieClose:hover span {
  background: #333; }

.ieError .ieWrapper {
  width: 750px;
  text-align: left;
  margin: 0 auto;
  background: url(../img/ie-exclamacao.jpg) 0 20px no-repeat;
  padding: 20px 0 0 0; }

.ieError h2,
.ieError p {
  margin-left: 100px; }

.ieError h2 {
  font-size: 15px;
  color: #C30;
  display: inline-block; }

.ieError p {
  display: inline;
  clear: left;
  float: left;
  width: 350px; }

.ieError ul {
  display: inline-block;
  float: right;
  width: 240px;
  margin: -30px 0 0 0; }

.ieError ul li {
  float: left; }

.ieError ul a {
  display: inline-block;
  color: #09C;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  width: 80px;
  cursor: pointer; }

.ieError ul a:hover {
  color: #C30; }

.ieError ul a span {
  display: inline-block;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat; }

.ieError a span.nome {
  width: 80px; }

.ieError a .ieNavegadoresChrome {
  background-image: url(../img/ie-navegador-chrome.jpg);
  height: 50px; }

.ieError a .ieNavegadoresFirefox {
  background-image: url(../img/ie-navegador-firefox.jpg);
  height: 50px; }

.ieError a .ieNavegadoresIe {
  background-image: url(../img/ie-navegador-ie.jpg);
  height: 50px; }

/* ERRO 404 */
#notfound {
  width: 50%;
  margin: 70px auto;
  font: 14px/1.8 "Trebuchet MS", Arial, Helvetica, sans-serif;
  background: url(../img/erro404-exclamacao.png) 0 bottom no-repeat;
  padding: 0 0 0 160px;
  min-height: 128px; }
  @media (max-width: 767px) {
    #notfound {
      background: none;
      padding: 0; } }
  @media (max-width: 420px) {
    #notfound {
      margin: 0;
      width: 100%; } }

#notfound h2 {
  color: #900;
  font-size: 22px;
  font-weight: 700; }

#notfound h3 {
  color: #333;
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: 700; }

/* FORMULARIOS */
.formulario {
  width: auto;
  max-width: 580px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto !important; }
  .formulario * {
    outline: none; }
  .formulario fieldset {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    display: inline-block;
    padding: 0; }
    .formulario fieldset:first-child {
      margin-left: auto; }
    .formulario fieldset:last-child {
      margin-right: auto; }
  .formulario label {
    *zoom: 1; }
    .formulario label:before, .formulario label:after {
      content: '';
      display: table; }
    .formulario label:after {
      clear: both; }
  .formulario [type=text],
  .formulario [type=file],
  .formulario textarea,
  .formulario select {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 186, 0, 0.2);
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    color: #000;
    float: left;
    font-size: 0.9375em;
    line-height: 1.4;
    margin-top: 12px;
    padding: 9px 13px 8px; }
    .formulario [type=text]:first-child,
    .formulario [type=file]:first-child,
    .formulario textarea:first-child,
    .formulario select:first-child {
      margin-left: auto; }
    .formulario [type=text]:last-child,
    .formulario [type=file]:last-child,
    .formulario textarea:last-child,
    .formulario select:last-child {
      margin-right: auto; }
    .formulario [type=text].error,
    .formulario [type=file].error,
    .formulario textarea.error,
    .formulario select.error {
      box-shadow: inset 0 0 0 1px #F2666D; }
    .formulario [type=text]::-webkit-input-placeholder,
    .formulario [type=file]::-webkit-input-placeholder,
    .formulario textarea::-webkit-input-placeholder,
    .formulario select::-webkit-input-placeholder {
      color: #444; }
    .formulario [type=text]::-moz-placeholder,
    .formulario [type=file]::-moz-placeholder,
    .formulario textarea::-moz-placeholder,
    .formulario select::-moz-placeholder {
      color: #444; }
    .formulario [type=text]:-moz-placeholder,
    .formulario [type=file]:-moz-placeholder,
    .formulario textarea:-moz-placeholder,
    .formulario select:-moz-placeholder {
      color: #444; }
    .formulario [type=text]:-ms-input-placeholder,
    .formulario [type=file]:-ms-input-placeholder,
    .formulario textarea:-ms-input-placeholder,
    .formulario select:-ms-input-placeholder {
      color: #444; }
    .formulario [type=text]:focus::-webkit-input-placeholder,
    .formulario [type=file]:focus::-webkit-input-placeholder,
    .formulario textarea:focus::-webkit-input-placeholder,
    .formulario select:focus::-webkit-input-placeholder {
      color: rgba(88, 67, 12, 0.2); }
    .formulario [type=text]:focus::-moz-placeholder,
    .formulario [type=file]:focus::-moz-placeholder,
    .formulario textarea:focus::-moz-placeholder,
    .formulario select:focus::-moz-placeholder {
      color: rgba(88, 67, 12, 0.2); }
    .formulario [type=text]:focus:-moz-placeholder,
    .formulario [type=file]:focus:-moz-placeholder,
    .formulario textarea:focus:-moz-placeholder,
    .formulario select:focus:-moz-placeholder {
      color: rgba(88, 67, 12, 0.2); }
    .formulario [type=text]:focus:-ms-input-placeholder,
    .formulario [type=file]:focus:-ms-input-placeholder,
    .formulario textarea:focus:-ms-input-placeholder,
    .formulario select:focus:-ms-input-placeholder {
      color: rgba(88, 67, 12, 0.2); }
  .formulario [type=text],
  .formulario [type=file],
  .formulario select {
    height: 43px; }
  .formulario textarea {
    display: inline-block;
    height: 110px;
    resize: vertical; }
  .formulario [type=submit] {
    width: auto;
    max-width: 200px;
    float: none;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    background: #444;
    border: none;
    border-radius: 20.5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.125em;
    font-weight: 700;
    height: 41px;
    line-height: 1.39;
    margin-top: 54px;
    padding: 0 17px;
    transition: opacity .4s;
    will-change: opacity; }
    .formulario [type=submit]:focus, .formulario [type=submit]:hover {
      opacity: .7; }
  .formulario label.error {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #F2666D;
    box-sizing: border-box;
    clear: both;
    color: #fff;
    float: left;
    font-size: 0.75em;
    font-weight: bold;
    margin-top: 3px;
    padding: 5px 10px; }
    .formulario label.error:first-child {
      margin-left: auto; }
    .formulario label.error:last-child {
      margin-right: auto; }
  .formulario label.error ~ [type=file] {
    margin-top: 8px; }
  .formulario [name="nome"] {
    margin-top: 0; }

/* PAGINACAO */
.paginacao {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin: 49px 0 0 -6px; }
  .paginacao:first-child {
    margin-left: auto; }
  .paginacao:last-child {
    margin-right: auto; }
  .paginacao li {
    float: left;
    margin-right: 7px; }
    @media (max-width: 1120px) {
      .paginacao li {
        margin-right: 15px; } }
  .paginacao a {
    background: #999;
    border-radius: 50%;
    display: inline-block;
    height: 11px;
    text-decoration: none;
    transition: opacity .4s;
    width: 11px;
    will-change: opacity; }
    .paginacao a:focus, .paginacao a:hover {
      opacity: .8; }
    .paginacao a.atual {
      background: #000; }
    @media (max-width: 1120px) {
      .paginacao a {
        height: 20px;
        width: 20px; } }
  .paginacao.paginacao-2 {
    width: calc(100% + 13px);
    margin: 27px 0 0 -3px; }
    .paginacao.paginacao-2 li {
      margin-right: 13px; }
      @media (max-width: 1120px) {
        .paginacao.paginacao-2 li {
          margin-right: 20px; } }
    .paginacao.paginacao-2 a {
      background: #000;
      height: 17px;
      width: 17px; }
      .paginacao.paginacao-2 a.atual {
        background: #FFBA00; }
      @media (max-width: 1120px) {
        .paginacao.paginacao-2 a {
          height: 24px;
          width: 24px; } }

:not(#geral).links-uteis {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px); }
  :not(#geral).links-uteis:first-child {
    margin-left: auto; }
  :not(#geral).links-uteis:last-child {
    margin-right: auto; }
  :not(#geral).links-uteis li {
    margin-bottom: 10px; }
    :not(#geral).links-uteis li:last-of-type {
      margin-bottom: 0; }
  :not(#geral).links-uteis a {
    background: #ddd;
    color: #666;
    display: block;
    font-size: 1.125em;
    line-height: 1.39;
    padding: 8px 103px 7px;
    text-decoration: none;
    transition: opacity .4s;
    will-change: opacity; }
    :not(#geral).links-uteis a:focus, :not(#geral).links-uteis a:hover {
      color: #58430C;
      opacity: .7; }
    @media (max-width: 600px) {
      :not(#geral).links-uteis a {
        padding: 8px 20px 7px; } }

@font-face {
  font-family: 'Verdana';
  src: local("Verdana"), url("fonts/hinted-subset-Verdana.woff2") format("woff2"), url("fonts/hinted-subset-Verdana.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Myriad Pro';
  src: local("Myriad Pro"), local("MyriadPro-Regular"), url("fonts/hinted-subset-MyriadPro-Regular.woff2") format("woff2"), url("fonts/hinted-subset-MyriadPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Myriad Pro';
  src: local("Myriad Pro Semibold"), local("MyriadPro-Semibold"), url("fonts/hinted-subset-MyriadPro-Semibold.woff2") format("woff2"), url("fonts/hinted-subset-MyriadPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Myriad Pro';
  src: local("Myriad Pro Bold"), local("MyriadPro-Bold"), url("fonts/hinted-subset-MyriadPro-Bold.woff2") format("woff2"), url("fonts/hinted-subset-MyriadPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.ocultaTexto span {
  display: inline-block;
  position: absolute;
  left: -9999px;
  text-indent: -9999px; }

body {
  font-family: 'Myriad Pro', sans-serif; }
  body.menu-aberto {
    overflow: hidden; }

button,
input,
select,
textarea {
  font-family: 'Myriad Pro', sans-serif; }

#geral {
  overflow: hidden; }

.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 20px;
  width: 100%; }
  .wrapper::before, .wrapper::after {
    clear: both;
    content: '';
    display: block; }

.sprite, .rodape-fale-conosco-texto::after, .rodape-seguradoras-lista .fs-carousel-control {
  display: inline-block; }

.relogio {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 175px;
  height: 129px; }

.rodape-fale-conosco-seta, .rodape-fale-conosco-texto::after {
  background-image: url(../img/sprite.png);
  background-position: -175px 0px;
  width: 166px;
  height: 63px; }

.seta-direita, .rodape-seguradoras-lista .fs-carousel-control.fs-carousel-control_next {
  background-image: url(../img/sprite.png);
  background-position: -175px -63px;
  width: 14px;
  height: 28px; }

.seta-esquerda, .rodape-seguradoras-lista .fs-carousel-control.fs-carousel-control_previous {
  background-image: url(../img/sprite.png);
  background-position: -189px -63px;
  width: 14px;
  height: 28px; }

.spacer {
  background-image: url(../img/sprite.png);
  background-position: -203px -63px;
  width: 1px;
  height: 1px; }

/* TOPO */
#topo {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  position: absolute;
  top: 0;
  z-index: 2; }
  #topo:first-child {
    margin-left: auto; }
  #topo:last-child {
    margin-right: auto; }
  @media (max-width: 950px) {
    #geral.index #topo {
      position: static; } }

.logo-container {
  float: left;
  margin-top: 18px; }
  @media (max-width: 460px) {
    .logo-container {
      margin-top: 10px; } }

.logo {
  background: url(../img/logo.svg) no-repeat 50%;
  background-size: contain; }

.logo a,
.logo {
  display: inline-block;
  height: 109px;
  width: 125px; }
  @media (max-width: 460px) {
    .logo a,
    .logo {
      height: 70px;
      width: 80px; } }

/* CONTATOS */
.topo-contatos {
  display: inline-block;
  float: right;
  margin: 39px 17px 0 0; }
  .topo-contatos .separador {
    display: inline-block;
    margin: 0 7px; }
    @media (max-width: 580px) {
      .topo-contatos .separador {
        display: none; } }
    @media (max-width: 460px) {
      .topo-contatos .separador {
        display: inline-block;
        max-width: 0 4px; } }
  @media (max-width: 650px) {
    .topo-contatos {
      margin: 20px 0 10px; } }
  @media (max-width: 580px) {
    .topo-contatos {
      margin: 10px 0; } }
  @media (max-width: 460px) {
    .topo-contatos {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      margin-bottom: 10px; }
      .topo-contatos:first-child {
        margin-left: auto; }
      .topo-contatos:last-child {
        margin-right: auto; } }

.topo-telefones {
  clear: right;
  color: #525252;
  display: inline-block;
  float: right;
  font-size: 1.875em;
  line-height: 1.27;
  margin-right: 2px; }
  .topo-telefones .fa {
    color: #FAC016;
    font-size: 0.53333em;
    line-height: 1.38;
    margin: 12px 22px 0 0; }
    @media (max-width: 460px) {
      .topo-telefones .fa {
        margin-right: 10px; } }
  @media (max-width: 580px) {
    .topo-telefones {
      font-size: 1.625em; }
      .topo-telefones .fa {
        font-size: 0.61538em; } }
  @media (max-width: 380px) {
    .topo-telefones {
      font-size: 1.3125em; }
      .topo-telefones .fa {
        font-size: 0.7619em; } }

.topo-telefone {
  color: #525252;
  display: inline-block;
  text-decoration: none; }
  .topo-telefone span {
    font-size: 0.6em; }
  @media (max-width: 580px) {
    .topo-telefone {
      clear: right;
      float: right; } }
  @media (max-width: 460px) {
    .topo-telefone {
      clear: none;
      float: none; } }

.topo-emails {
  clear: right;
  color: #545454;
  display: inline-block;
  float: right;
  font-size: 0.9375em;
  line-height: 1.4;
  margin-top: 7px; }
  .topo-emails .fa {
    color: #FAC016;
    margin-right: 20px; }
    @media (max-width: 460px) {
      .topo-emails .fa {
        margin-right: 10px; } }

.topo-email {
  color: #545454;
  display: inline-block;
  text-decoration: none; }

#menuToggler {
  display: none; }
  #menuToggler:checked ~ .menuToggler {
    color: #fff; }
    @media (max-width: 460px) {
      #menuToggler:checked ~ .menuToggler {
        height: 0;
        position: static; } }
    #menuToggler:checked ~ .menuToggler .fa::before {
      content: '\f05c';
      position: fixed;
      right: 15px;
      text-align: center;
      top: 15px;
      width: 1.01em;
      z-index: 10; }
  #menuToggler:checked ~ .topo-menu {
    opacity: 1;
    visibility: visible; }

.menuToggler {
  clear: right;
  color: #FAC016;
  cursor: pointer;
  display: none;
  float: right;
  font-size: 2em;
  height: 35px;
  margin: 10px 0;
  z-index: 8; }
  @media (max-width: 650px) {
    .menuToggler {
      display: inline-block; } }
  @media (max-width: 460px) {
    .menuToggler {
      position: absolute;
      margin: 0;
      right: 20px;
      top: 20px; } }

.topo-menu {
  border-top: solid 1px #ccc;
  display: flex;
  float: left;
  margin-top: 23px;
  width: 100%; }
  @media (max-width: 650px) {
    .topo-menu {
      background: rgba(216, 163, 5, 0.7);
      border: none;
      bottom: 0;
      flex-direction: column;
      left: 0;
      margin: 0;
      overflow: auto;
      opacity: 0;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity .4s, visibility .4s;
      visibility: hidden;
      will-change: opacity;
      z-index: 9; } }

.topo-menu-item {
  flex: 1 0 auto; }
  @media (max-width: 650px) {
    .topo-menu-item {
      align-items: center;
      display: flex;
      justify-content: center; } }

.topo-menu-link {
  color: #555;
  display: block;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.38;
  padding: 9px 0 7px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  .topo-menu-link:focus, .topo-menu-link:hover {
    background: #ccc;
    color: #fff; }
  @media (max-width: 860px) {
    .topo-menu-link {
      font-size: 0.9375em;
      font-weight: 400;
      text-transform: none; } }
  @media (max-width: 650px) {
    .topo-menu-link {
      color: #fff;
      flex: 1 0 auto;
      font-size: 1.5em;
      line-height: 2;
      padding: 0;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.3); } }

/* Banner */
.banner {
  height: 770px; }
  .banner .fs-carousel-item {
    opacity: 0;
    transition: .4s;
    visibility: hidden; }
    .banner .fs-carousel-item.fs-carousel-visible {
      opacity: 1;
      visibility: visible; }
  .banner li:not(:first-child) {
    visibility: hidden; }
  .banner a {
    display: inline-block;
    float: left;
    width: 100%; }
    .banner a img {
      float: left;
      left: 50%;
      position: relative;
      transform: translateX(-50%); }
    .banner a[href=""] {
      cursor: default; }
  @media (max-width: 1160px) {
    .banner {
      height: auto; }
      .banner a img {
        height: auto;
        width: 160%; } }
  @media (max-width: 520px) {
    .banner {
      overflow: initial !important; } }

.banner-interno {
  height: 320px;
  position: relative; }

.banner-interno-imagem {
  left: 50%;
  position: relative;
  transform: translateX(-50%); }

/* BANNER ATENDIMENTO 24 HORAS */
.atendimento-24-horas {
  background: #444;
  color: #fff;
  font-size: 4.25em;
  height: 129px;
  line-height: 1.15; }
  .atendimento-24-horas .wrapper {
    position: relative; }
  .atendimento-24-horas .sprite, .atendimento-24-horas .rodape-fale-conosco-texto::after, .atendimento-24-horas .rodape-seguradoras-lista .fs-carousel-control, .rodape-seguradoras-lista .atendimento-24-horas .fs-carousel-control {
    position: relative;
    left: 33.89667%;
    position: absolute;
    top: 0;
    transform: translateX(3px);
    z-index: 1; }
    @media (max-width: 540px) {
      .atendimento-24-horas .sprite, .atendimento-24-horas .rodape-fale-conosco-texto::after, .atendimento-24-horas .rodape-seguradoras-lista .fs-carousel-control, .rodape-seguradoras-lista .atendimento-24-horas .fs-carousel-control {
        position: static;
        left: 0;
        position: absolute; } }
  @media (max-width: 540px) {
    .atendimento-24-horas {
      font-size: 2.5em; } }

.atendimento-24-horas-frase {
  position: relative;
  left: 42.37083%;
  display: inline-block;
  margin-top: 10px;
  text-transform: uppercase;
  transform: translateX(25px); }
  @media (max-width: 540px) {
    .atendimento-24-horas-frase {
      position: static;
      left: 0;
      margin: 30px 0 0 20%; } }

.atendimento-24-horas-frase-linha-1 {
  clear: left;
  display: inline-block;
  float: left;
  font-size: 0.58824em;
  line-height: 1.2; }

.atendimento-24-horas-frase-linha-2 {
  clear: left;
  display: inline-block;
  float: left;
  margin-top: -10px;
  text-transform: none; }

.atendimento-24-horas-numero {
  color: #FFBA00; }

#rodape-fale-conosco {
  background: url(../img/rodape-fale-conosco.jpg) 50% no-repeat;
  background-size: cover;
  color: #fff; }

.rodape-fale-conosco-titulos {
  float: left;
  margin: 67px 0 0 8.73%;
  width: 36.61%; }
  @media (max-width: 1140px) {
    .rodape-fale-conosco-titulos {
      font-size: 0.9375em; } }
  @media (max-width: 1060px) {
    .rodape-fale-conosco-titulos {
      font-size: 0.875em; } }
  @media (max-width: 800px) {
    .rodape-fale-conosco-titulos {
      margin-left: 0;
      width: 45.34%; } }
  @media (max-width: 640px) {
    .rodape-fale-conosco-titulos {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .rodape-fale-conosco-titulos:first-child {
        margin-left: auto; }
      .rodape-fale-conosco-titulos:last-child {
        margin-right: auto; } }

.rodape-fale-conosco-titulo {
  font-size: 2.375em;
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 4px 4px 6px #000; }

.rodape-fale-conosco-texto {
  font-size: 1.375em;
  font-weight: 600;
  line-height: 1.18;
  margin-top: 15px;
  position: relative;
  text-shadow: 4px 4px 6px #000; }
  .rodape-fale-conosco-texto::after {
    content: '';
    position: absolute;
    right: -14px;
    top: calc(100% + 62px); }
    @media (max-width: 910px) {
      .rodape-fale-conosco-texto::after {
        top: calc(100% + 20px); } }
    @media (max-width: 640px) {
      .rodape-fale-conosco-texto::after {
        content: none; } }

.rodape-fale-conosco-container {
  float: right;
  margin: 71px 6.78% 63px 0;
  width: 42.2%; }
  .rodape-fale-conosco-container [type=text],
  .rodape-fale-conosco-container textarea {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
    border: solid 1px rgba(255, 255, 255, 0.61);
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.39;
    outline: none;
    padding: 6px 15px; }
    .rodape-fale-conosco-container [type=text]:first-child,
    .rodape-fale-conosco-container textarea:first-child {
      margin-left: auto; }
    .rodape-fale-conosco-container [type=text]:last-child,
    .rodape-fale-conosco-container textarea:last-child {
      margin-right: auto; }
    .rodape-fale-conosco-container [type=text]:not(:first-child),
    .rodape-fale-conosco-container textarea:not(:first-child) {
      margin-top: 6px; }
    .rodape-fale-conosco-container [type=text]::-webkit-input-placeholder,
    .rodape-fale-conosco-container textarea::-webkit-input-placeholder {
      color: #fff; }
    .rodape-fale-conosco-container [type=text]::-moz-placeholder,
    .rodape-fale-conosco-container textarea::-moz-placeholder {
      color: #fff; }
    .rodape-fale-conosco-container [type=text]:-moz-placeholder,
    .rodape-fale-conosco-container textarea:-moz-placeholder {
      color: #fff; }
    .rodape-fale-conosco-container [type=text]:-ms-input-placeholder,
    .rodape-fale-conosco-container textarea:-ms-input-placeholder {
      color: #fff; }
    .rodape-fale-conosco-container [type=text]:focus::-webkit-input-placeholder,
    .rodape-fale-conosco-container textarea:focus::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.2); }
    .rodape-fale-conosco-container [type=text]:focus::-moz-placeholder,
    .rodape-fale-conosco-container textarea:focus::-moz-placeholder {
      color: rgba(255, 255, 255, 0.2); }
    .rodape-fale-conosco-container [type=text]:focus:-moz-placeholder,
    .rodape-fale-conosco-container textarea:focus:-moz-placeholder {
      color: rgba(255, 255, 255, 0.2); }
    .rodape-fale-conosco-container [type=text]:focus:-ms-input-placeholder,
    .rodape-fale-conosco-container textarea:focus:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.2); }
    .rodape-fale-conosco-container [type=text].error,
    .rodape-fale-conosco-container textarea.error {
      border-color: #F2666D; }
  .rodape-fale-conosco-container [type=text] {
    height: 39px; }
  .rodape-fale-conosco-container textarea {
    height: 93px;
    resize: vertical; }
  .rodape-fale-conosco-container [type=submit] {
    background: #444;
    float: right;
    border: none;
    border-radius: 21.5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.125em;
    font-weight: 600;
    height: 43px;
    margin-top: 37px;
    min-width: 42.17%;
    transition: opacity .4s;
    will-change: opacity; }
    .rodape-fale-conosco-container [type=submit]:focus, .rodape-fale-conosco-container [type=submit]:hover {
      opacity: .7; }
  .rodape-fale-conosco-container label.error {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(242, 102, 109, 0.6);
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
    font-size: 0.8125em;
    font-weight: 500;
    margin-top: 5px;
    padding: 5px; }
    .rodape-fale-conosco-container label.error:first-child {
      margin-left: auto; }
    .rodape-fale-conosco-container label.error:last-child {
      margin-right: auto; }
  @media (max-width: 640px) {
    .rodape-fale-conosco-container {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      margin: 40px 0; }
      .rodape-fale-conosco-container:first-child {
        margin-left: auto; }
      .rodape-fale-conosco-container:last-child {
        margin-right: auto; } }

.rodape-seguradoras-titulo {
  margin-top: 65px;
  text-align: left;
  text-indent: 3px; }
  #geral:not(.index) .rodape-seguradoras-titulo {
    margin-top: 0; }

.rodape-seguradoras-lista {
  width: auto;
  max-width: 1030px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 64px;
  margin-top: 28px; }
  .rodape-seguradoras-lista .fs-carousel-wrapper {
    width: calc(100% - 90px);
    margin: 0 45px;
    z-index: 2; }
  .rodape-seguradoras-lista .fs-carousel-controls {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
  .rodape-seguradoras-lista .fs-carousel-control {
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-indent: 200vw;
    transition: opacity .4s;
    will-change: opacity; }
    .rodape-seguradoras-lista .fs-carousel-control.fs-carousel-control_previous {
      float: left; }
    .rodape-seguradoras-lista .fs-carousel-control.fs-carousel-control_next {
      float: right; }
    .rodape-seguradoras-lista .fs-carousel-control:focus, .rodape-seguradoras-lista .fs-carousel-control:hover {
      opacity: .7; }
  .rodape-seguradoras-lista .fs-carousel-canister {
    align-items: center;
    display: flex;
    justify-content: center; }

.rodape-seguradoras-item {
  text-align: center; }

.rodape-seguradoras-logo {
  height: auto;
  max-width: 100%;
  width: auto; }

#rodape {
  background: #444;
  color: #FFBA00; }

.rodape-menu {
  width: auto;
  max-width: 980px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  border-bottom: solid 1px #fff;
  display: flex; }
  @media (max-width: 770px) {
    .rodape-menu {
      font-size: 0.9375em; } }
  @media (max-width: 620px) {
    .rodape-menu {
      flex-wrap: wrap; } }

.rodape-menu-item {
  flex: 1 0 auto; }
  @media (max-width: 620px) {
    .rodape-menu-item {
      flex-basis: 25%; } }
  @media (max-width: 420px) {
    .rodape-menu-item {
      flex-basis: 50%; } }

.rodape-menu-link {
  color: #fff;
  display: block;
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.4;
  padding: 26px 0 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  .rodape-menu-link:focus, .rodape-menu-link:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #FFBA00; }
  @media (max-width: 770px) {
    .rodape-menu-link {
      text-transform: none; } }
  @media (max-width: 680px) {
    .rodape-menu-link {
      font-weight: 400; } }
  @media (max-width: 420px) {
    .rodape-menu-link {
      padding: 15px 0; } }

/* CONTATOS */
.rodape-contatos {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #FFBA00;
  display: inline-block;
  margin-top: 58px;
  text-align: center; }
  .rodape-contatos:first-child {
    margin-left: auto; }
  .rodape-contatos:last-child {
    margin-right: auto; }
  .rodape-contatos .separador {
    display: inline-block;
    margin: 0 7px; }

.rodape-telefones {
  display: inline-block;
  font-size: 1.1875em;
  line-height: 1.32; }
  .rodape-telefones .fa {
    font-size: 0.84211em;
    line-height: 1.38;
    margin: 2px 12px 0 0; }
  @media (max-width: 620px) {
    .rodape-telefones {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin: 5px 0; }
      .rodape-telefones:first-child {
        margin-left: auto; }
      .rodape-telefones:last-child {
        margin-right: auto; } }

.rodape-telefone {
  color: #FFBA00;
  display: inline-block;
  text-decoration: none; }
  .rodape-telefone span {
    font-size: 0.6em; }

.rodape-emails {
  display: inline-block;
  font-size: 0.9375em;
  line-height: 1.4;
  margin-left: 28px; }
  .rodape-emails .fa {
    margin-right: 20px; }
  @media (max-width: 620px) {
    .rodape-emails {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin: 5px 0; }
      .rodape-emails:first-child {
        margin-left: auto; }
      .rodape-emails:last-child {
        margin-right: auto; } }

.rodape-email {
  color: #FFBA00;
  display: inline-block;
  text-decoration: none; }

/* Address */
.rodape-endereco {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #FFBA00;
  display: inline-block;
  font-size: 1.0625em;
  line-height: 1.41;
  margin-top: 31px;
  text-align: center; }
  .rodape-endereco:first-child {
    margin-left: auto; }
  .rodape-endereco:last-child {
    margin-right: auto; }
  @media (max-width: 560px) {
    .rodape-endereco {
      font-size: 0.9375em;
      margin-top: 25px; } }

.copyright {
  width: auto;
  max-width: 980px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  border-top: solid 1px #595959;
  color: #fff;
  font: 0.625em/1.7 "Verdana", sans-serif;
  margin-top: 51px;
  padding: 8px 0;
  text-align: center; }

.selos {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  padding: 5px 0 10px;
  text-align: center; }
  .selos:first-child {
    margin-left: auto; }
  .selos:last-child {
    margin-right: auto; }

.selo-vn {
  background: url(http://www.vianetbrasil.com.br/selo_claro.png);
  display: inline-block;
  height: 17px;
  margin: 0 10px;
  vertical-align: middle;
  width: 100px; }

.home-servicos {
  width: auto;
  max-width: 980px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  *zoom: 1; }
  .home-servicos:before, .home-servicos:after {
    content: '';
    display: table; }
  .home-servicos:after {
    clear: both; }

.home-servicos-item {
  float: left;
  clear: none;
  width: 23.47%;
  margin-left: 0;
  margin-right: 2.04%;
  margin-bottom: 70px;
  margin-top: 37px; }
  .home-servicos-item:nth-of-type(n) {
    margin-right: 2.04%;
    float: left;
    clear: none; }
  .home-servicos-item:nth-of-type(4n) {
    margin-right: 0;
    float: right; }
  .home-servicos-item:nth-of-type(4n + 1) {
    clear: both; }
  @media (max-width: 768px) {
    .home-servicos-item {
      float: left;
      clear: none;
      width: 31.33333%;
      margin-left: 0;
      margin-right: 3%; }
      .home-servicos-item:nth-of-type(n) {
        margin-right: 3%;
        float: left;
        clear: none; }
      .home-servicos-item:nth-of-type(3n) {
        margin-right: 0;
        float: right; }
      .home-servicos-item:nth-of-type(3n + 1) {
        clear: both; } }
  @media (max-width: 580px) {
    .home-servicos-item {
      float: left;
      clear: none;
      width: 48%;
      margin-left: 0;
      margin-right: 4%; }
      .home-servicos-item:nth-of-type(n) {
        margin-right: 4%;
        float: left;
        clear: none; }
      .home-servicos-item:nth-of-type(2n) {
        margin-right: 0;
        float: right; }
      .home-servicos-item:nth-of-type(2n + 1) {
        clear: both; } }
  @media (max-width: 380px) {
    .home-servicos-item {
      font-size: 0.875em;
      margin-top: 30px;
      margin-bottom: 30px; } }

.home-servicos-link {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  position: relative;
  text-decoration: none; }
  .home-servicos-link:first-child {
    margin-left: auto; }
  .home-servicos-link:last-child {
    margin-right: auto; }

.home-servicos-nome {
  background: #FFBA00;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 0.9375em;
  font-weight: bold;
  line-height: 21px;
  padding: 18px 0 19px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.home-servicos-imagem-container {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 140.43%;
  position: relative; }
  .home-servicos-imagem-container:first-child {
    margin-left: auto; }
  .home-servicos-imagem-container:last-child {
    margin-right: auto; }

.home-servicos-imagem {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%; }

.home-servicos-descricao {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #111;
  background-image: -webkit-linear-gradient(#111, transparent);
  background-image: linear-gradient(#111, transparent);
  background-color: transparent !important;
  left: 0;
  padding-top: 74.35%;
  position: absolute;
  top: 58px; }
  .home-servicos-descricao:first-child {
    margin-left: auto; }
  .home-servicos-descricao:last-child {
    margin-right: auto; }

.home-servicos-descricao-texto {
  color: #fff;
  font-size: 0.9375em;
  font-weight: 600;
  left: 50%;
  line-height: 21px;
  max-height: 63px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 22px;
  transform: translateX(-50%);
  width: calc(100% - 12px); }
  @media (max-width: 580px) {
    .home-servicos-descricao-texto {
      max-height: 84px; } }

.home-servicos-icone, .servicos-icone {
  background: #FFBA00;
  border: solid 1px #FFBA00;
  border-radius: 50%;
  box-sizing: border-box;
  bottom: 0;
  color: #58430C;
  font-size: 2.5em;
  left: 50%;
  padding-top: 43.04%;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, 50%);
  width: 43.04%; }
  .home-servicos-icone::before, .servicos-icone::before {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }
  .home-servicos-icone::after, .servicos-icone::after {
    background: #FFBA00;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .4s;
    width: 100%; }
    .home-servicos-link:hover .home-servicos-icone::after, .home-servicos-link:hover .servicos-icone::after,
    .home-servicos-link:focus .home-servicos-icone::after,
    .home-servicos-link:focus .servicos-icone::after {
      opacity: 0;
      will-change: opacity; }
  .home-servicos-item:nth-child(3) .home-servicos-icone, .home-servicos-item:nth-child(3) .servicos-icone {
    font-size: 3em; }
  .home-servicos-item:nth-child(4) .home-servicos-icone, .home-servicos-item:nth-child(4) .servicos-icone {
    font-size: 2.8125em; }
  .home-servicos-link:hover .home-servicos-icone, .home-servicos-link:hover .servicos-icone,
  .home-servicos-link:focus .home-servicos-icone,
  .home-servicos-link:focus .servicos-icone {
    background: #fff; }

.home-todos-os-servicos {
  width: auto;
  max-width: 280px;
  float: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  background: #444;
  border-radius: 30px;
  color: #fff;
  font-size: 1.375em;
  font-weight: 600;
  line-height: 61px;
  margin-top: 78px;
  margin-bottom: 91px;
  text-align: center;
  text-decoration: none;
  transition: opacity .4s;
  will-change: opacity; }
  .home-todos-os-servicos:focus, .home-todos-os-servicos:hover {
    color: #fff;
    opacity: .7; }

:not(#geral).servicos {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin-left: -13px;
  max-width: calc(100vw - 40px);
  width: calc(100% + 13px); }
  :not(#geral).servicos:first-child {
    margin-left: auto; }
  :not(#geral).servicos:last-child {
    margin-right: auto; }
  @media (max-width: 1058px) {
    :not(#geral).servicos {
      max-width: initial;
      width: 100%; } }
  @media (max-width: 680px) {
    :not(#geral).servicos {
      width: auto;
      max-width: 80%;
      float: none;
      display: block;
      margin-right: auto;
      margin-left: auto;
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 440px) {
    :not(#geral).servicos {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; }
      :not(#geral).servicos:first-child {
        margin-left: auto; }
      :not(#geral).servicos:last-child {
        margin-right: auto; } }

.servicos-item {
  float: left;
  clear: none;
  width: 38.758%;
  margin-left: 0;
  margin-right: 2.07%; }
  .servicos-item:nth-of-type(n) {
    margin-right: 2.07%;
    float: left;
    clear: none; }
  .servicos-item:nth-of-type(2n) {
    margin-right: 0;
    float: right; }
  .servicos-item:nth-of-type(2n + 1) {
    clear: both; }
  .servicos-item:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 28px; }
  @media (max-width: 1058px) {
    .servicos-item {
      float: left;
      clear: none;
      width: 48.5%;
      margin-left: 0;
      margin-right: 3%; }
      .servicos-item:nth-of-type(n) {
        margin-right: 3%;
        float: left;
        clear: none; }
      .servicos-item:nth-of-type(2n) {
        margin-right: 0;
        float: right; }
      .servicos-item:nth-of-type(2n + 1) {
        clear: both; } }
  @media (max-width: 680px) {
    .servicos-item {
      display: block;
      clear: both;
      float: none;
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .servicos-item:first-child {
        margin-left: auto; }
      .servicos-item:last-child {
        margin-right: auto; }
      .servicos-item:not(:nth-child(1)) {
        margin-top: 28px; } }

.servicos-link {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-decoration: none; }
  .servicos-link:first-child {
    margin-left: auto; }
  .servicos-link:last-child {
    margin-right: auto; }

.servicos-icone {
  border-width: 2px;
  bottom: initial;
  float: left;
  height: 99px;
  left: initial;
  margin: 0 36px 0 -19px;
  padding: 0;
  position: relative;
  transform: none;
  width: 99px; }
  .servicos-icone::after {
    border-radius: 49%; }
    .servicos-link:hover .servicos-icone::after,
    .servicos-link:focus .servicos-icone::after {
      opacity: 0;
      will-change: opacity; }
  .servicos-link:hover .servicos-icone,
  .servicos-link:focus .servicos-icone {
    background: #fff; }
  @media (max-width: 1058px) {
    .servicos-icone {
      margin-left: 0; } }
  @media (max-width: 890px) {
    .servicos-icone {
      height: 80px;
      margin-right: 25px;
      width: 80px; } }
  @media (max-width: 380px) {
    .servicos-icone {
      font-size: 1.25em;
      height: 60px;
      margin-right: 15px;
      width: 60px; } }

.servicos-nome {
  color: #58430C;
  font-size: 1.375em;
  font-weight: 700;
  letter-spacing: -.3px;
  line-height: 99px;
  text-transform: uppercase; }
  @media (max-width: 890px) {
    .servicos-nome {
      font-size: 1.25em;
      font-weight: 400; } }
  @media (max-width: 890px) {
    .servicos-nome {
      font-size: 1.0625em;
      line-height: 80px; } }
  @media (max-width: 380px) {
    .servicos-nome {
      font-size: 0.9375em;
      line-height: 60px; } }

.seguradoras-lista {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px); }
  .seguradoras-lista:first-child {
    margin-left: auto; }
  .seguradoras-lista:last-child {
    margin-right: auto; }

.seguradoras-item {
  float: left;
  clear: none;
  width: 18.368%;
  margin-left: 0;
  margin-right: 2.04%;
  align-items: center;
  border: solid 1px #ccc;
  box-sizing: border-box;
  display: flex;
  height: 116px;
  justify-content: center; }
  .seguradoras-item:nth-of-type(n) {
    margin-right: 2.04%;
    float: left;
    clear: none; }
  .seguradoras-item:nth-of-type(5n) {
    margin-right: 0;
    float: right; }
  .seguradoras-item:nth-of-type(5n + 1) {
    clear: both; }
  .seguradoras-item:nth-child(5) ~ .seguradoras-item {
    margin-top: 23px; }
  @media (max-width: 767px) {
    .seguradoras-item {
      float: left;
      clear: none;
      width: 22.75%;
      margin-left: 0;
      margin-right: 3%; }
      .seguradoras-item:nth-of-type(n) {
        margin-right: 3%;
        float: left;
        clear: none; }
      .seguradoras-item:nth-of-type(4n) {
        margin-right: 0;
        float: right; }
      .seguradoras-item:nth-of-type(4n + 1) {
        clear: both; }
      .seguradoras-item:nth-child(4) ~ .seguradoras-item {
        margin-top: 23px; } }
  @media (max-width: 540px) {
    .seguradoras-item {
      float: left;
      clear: none;
      width: 30.66667%;
      margin-left: 0;
      margin-right: 4%; }
      .seguradoras-item:nth-of-type(n) {
        margin-right: 4%;
        float: left;
        clear: none; }
      .seguradoras-item:nth-of-type(3n) {
        margin-right: 0;
        float: right; }
      .seguradoras-item:nth-of-type(3n + 1) {
        clear: both; }
      .seguradoras-item:nth-child(3) ~ .seguradoras-item {
        margin-top: 23px; } }
  @media (max-width: 400px) {
    .seguradoras-item {
      float: left;
      clear: none;
      width: 47.5%;
      margin-left: 0;
      margin-right: 5%; }
      .seguradoras-item:nth-of-type(n) {
        margin-right: 5%;
        float: left;
        clear: none; }
      .seguradoras-item:nth-of-type(2n) {
        margin-right: 0;
        float: right; }
      .seguradoras-item:nth-of-type(2n + 1) {
        clear: both; }
      .seguradoras-item:nth-child(2) ~ .seguradoras-item {
        margin-top: 23px; } }

.seguradoras-logo {
  height: auto;
  max-height: calc(100% - 20px);
  max-width: calc(100% - 20px);
  width: auto; }

.noticias {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px); }
  .noticias:first-child {
    margin-left: auto; }
  .noticias:last-child {
    margin-right: auto; }

.noticias-item {
  border-bottom: solid 1px #eee;
  padding: 0 0 24px 10px; }
  .noticias-item:not(:nth-child(1)) {
    margin-top: 46px; }

.noticias-link {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-decoration: none; }
  .noticias-link:first-child {
    margin-left: auto; }
  .noticias-link:last-child {
    margin-right: auto; }

.noticias-data {
  background: #FFBA00;
  color: #58430C;
  display: inline-block;
  float: left;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.38;
  margin-right: 26px;
  padding: 8px 0 6px;
  text-align: center;
  width: 120px; }
  @media (max-width: 480px) {
    .noticias-data {
      font-size: 0.9375em;
      font-weight: 500;
      margin-right: 15px;
      width: 100px; } }

.noticias-descricao {
  color: #666;
  font-size: 0.9375em;
  line-height: 1.3; }

.conteudoNoticia {
  display: block;
  clear: both;
  float: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #666;
  display: inline-block;
  font-size: 0.9375em;
  line-height: 1.4;
  margin-left: -13px !important;
  width: calc(100% + 13px); }
  .conteudoNoticia:first-child {
    margin-left: auto; }
  .conteudoNoticia:last-child {
    margin-right: auto; }
  .conteudoNoticia .noticia-titulo {
    color: #666;
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    text-transform: none; }
  .conteudoNoticia .noticia-data {
    color: #FFBA00;
    font-size: 0.9375em;
    line-height: 1.4;
    margin-top: 3px; }
  .conteudoNoticia p {
    margin: 3em 0 0 0; }
  .conteudoNoticia .foto {
    display: block;
    clear: both;
    float: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin: 25px 0 3em; }
    .conteudoNoticia .foto:first-child {
      margin-left: auto; }
    .conteudoNoticia .foto:last-child {
      margin-right: auto; }
    .conteudoNoticia .foto::before {
      overflow: hidden;
      padding-top: 27.24%; }
      @media (max-width: 480px) {
        .conteudoNoticia .foto::before {
          padding-top: 40%; } }
      @media (max-width: 360px) {
        .conteudoNoticia .foto::before {
          padding-top: 70%; } }
