:not(#geral).links-uteis {
  @include stack();
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px);
  li {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    background: $cor-cinza-claro-2;
    color: $cor-cinza-medio-6;
    display: block;
    font-size: em(18);
    line-height: 1.39;
    padding: 8px 103px 7px;
    text-decoration: none;
    transition: opacity .4s;
    will-change: opacity;
    &:focus,
    &:hover {
      color: $cor-marrom;
      opacity: .7;
    }
    @media (max-width: 600px) {
      padding: 8px 20px 7px;
    }
  }
}
