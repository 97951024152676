@font-face {
  font-family: 'Verdana';
  src: local('Verdana'),
    url('fonts/hinted-subset-Verdana.woff2') format('woff2'),
    url('fonts/hinted-subset-Verdana.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro'), local('MyriadPro-Regular'),
    url('fonts/hinted-subset-MyriadPro-Regular.woff2') format('woff2'),
    url('fonts/hinted-subset-MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro Semibold'), local('MyriadPro-Semibold'),
    url('fonts/hinted-subset-MyriadPro-Semibold.woff2') format('woff2'),
    url('fonts/hinted-subset-MyriadPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro Bold'), local('MyriadPro-Bold'),
    url('fonts/hinted-subset-MyriadPro-Bold.woff2') format('woff2'),
    url('fonts/hinted-subset-MyriadPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


.ocultaTexto span {
  display: inline-block;
  position: absolute;
  left: -9999px;
  text-indent: -9999px
}

body {
  font-family: 'Myriad Pro', sans-serif;
  &.menu-aberto {
    overflow: hidden;
  }
}

button,
input,
select,
textarea {
  font-family: 'Myriad Pro', sans-serif;
}

#geral {
  overflow: hidden;
}

.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1220px;
  padding: 0 20px;
  width: 100%;
  &::before,
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.sprite {
  display: inline-block;
}

@include sprites($spritesheet-sprites);
