/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$relogio-name: 'relogio';
$relogio-x: 0px;
$relogio-y: 0px;
$relogio-offset-x: 0px;
$relogio-offset-y: 0px;
$relogio-width: 175px;
$relogio-height: 129px;
$relogio-total-width: 341px;
$relogio-total-height: 129px;
$relogio-image: '../img/sprite.png';
$relogio: (0px, 0px, 0px, 0px, 175px, 129px, 341px, 129px, '../img/sprite.png', 'relogio', );
$rodape-fale-conosco-seta-name: 'rodape-fale-conosco-seta';
$rodape-fale-conosco-seta-x: 175px;
$rodape-fale-conosco-seta-y: 0px;
$rodape-fale-conosco-seta-offset-x: -175px;
$rodape-fale-conosco-seta-offset-y: 0px;
$rodape-fale-conosco-seta-width: 166px;
$rodape-fale-conosco-seta-height: 63px;
$rodape-fale-conosco-seta-total-width: 341px;
$rodape-fale-conosco-seta-total-height: 129px;
$rodape-fale-conosco-seta-image: '../img/sprite.png';
$rodape-fale-conosco-seta: (175px, 0px, -175px, 0px, 166px, 63px, 341px, 129px, '../img/sprite.png', 'rodape-fale-conosco-seta', );
$seta-direita-name: 'seta-direita';
$seta-direita-x: 175px;
$seta-direita-y: 63px;
$seta-direita-offset-x: -175px;
$seta-direita-offset-y: -63px;
$seta-direita-width: 14px;
$seta-direita-height: 28px;
$seta-direita-total-width: 341px;
$seta-direita-total-height: 129px;
$seta-direita-image: '../img/sprite.png';
$seta-direita: (175px, 63px, -175px, -63px, 14px, 28px, 341px, 129px, '../img/sprite.png', 'seta-direita', );
$seta-esquerda-name: 'seta-esquerda';
$seta-esquerda-x: 189px;
$seta-esquerda-y: 63px;
$seta-esquerda-offset-x: -189px;
$seta-esquerda-offset-y: -63px;
$seta-esquerda-width: 14px;
$seta-esquerda-height: 28px;
$seta-esquerda-total-width: 341px;
$seta-esquerda-total-height: 129px;
$seta-esquerda-image: '../img/sprite.png';
$seta-esquerda: (189px, 63px, -189px, -63px, 14px, 28px, 341px, 129px, '../img/sprite.png', 'seta-esquerda', );
$spacer-name: 'spacer';
$spacer-x: 203px;
$spacer-y: 63px;
$spacer-offset-x: -203px;
$spacer-offset-y: -63px;
$spacer-width: 1px;
$spacer-height: 1px;
$spacer-total-width: 341px;
$spacer-total-height: 129px;
$spacer-image: '../img/sprite.png';
$spacer: (203px, 63px, -203px, -63px, 1px, 1px, 341px, 129px, '../img/sprite.png', 'spacer', );
$spritesheet-width: 341px;
$spritesheet-height: 129px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($relogio, $rodape-fale-conosco-seta, $seta-direita, $seta-esquerda, $spacer, );
$spritesheet: (341px, 129px, '../img/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
