// Terceiros
@import '../../../node_modules/bourbon/app/assets/stylesheets/bourbon';
@import '../../../node_modules/jeet/scss/jeet/jeet';
@import '../../../node_modules/font-awesome/scss/variables';
$fa-font-path: "fonts";
@import '../../../node_modules/font-awesome/scss/mixins';
@import '../../../node_modules/font-awesome/scss/path';
@import '../../../node_modules/font-awesome/scss/core';
@import '../../../node_modules/font-awesome/scss/icons';
@import '../../../node_modules/lightgallery/src/css/lightgallery';
@import '../../../node_modules/lightgallery/src/css/lg-transitions';
@font-face {
  font-family: 'lg';
  src: url("fonts/lg.woff2?n1z373") format("woff2"), url("fonts/lg.woff?n1z373") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Dependencias
@import 'outros/carousel';

// Configurações
@import 'configuracoes/variaveis';
@import 'configuracoes/reset';
@import 'configuracoes/cores';
@import 'configuracoes/sprite';

// Componentes
@import 'componentes/conteudo-interno';
@import 'componentes/erro-ie';
@import 'componentes/erro-404';
@import 'componentes/formularios';
@import 'componentes/paginacao';
@import 'componentes/links-uteis';

// Layout
@import 'layout/geral';
@import 'layout/topo';
@import 'layout/rodape';
@import 'layout/home';

// Páginas
@import 'layout/servicos';
@import 'layout/seguradoras';
@import 'layout/noticias';
