/* FORMULARIOS */

.formulario {
  @include center(580px);
  margin: 0 auto !important;
  * {
    outline: none;
  }
  fieldset {
    @include stack();
    border: none;
    display: inline-block;
    padding: 0;
  }
  label {
    @include cf();
  }
  [type=text],
  [type=file],
  textarea,
  select {
    @include stack();
    background: rgba($cor-amarelo-2, .2);
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    color: $cor-preto;
    float: left;
    font-size: em(15);
    line-height: 1.4;
    margin-top: 12px;
    padding: 9px 13px 8px;
    &.error {
      box-shadow: inset 0 0 0 1px $cor-vermelho-claro;
    }
    @include placeholder {
      color: $cor-cinza-escuro;
    }
    &:focus {
      @include placeholder {
        color: rgba($cor-marrom, .2);
      }
    }
  }
  [type=text],
  [type=file],
  select {
    height: 43px;
  }
  textarea {
    display: inline-block;
    height: 110px;
    resize: vertical;
  }
  [type=submit] {
    @include center(200px);
    background: $cor-cinza-escuro;
    border: none;
    border-radius: 20.5px;
    color: $cor-branco;
    cursor: pointer;
    font-size: em(18);
    font-weight: 700;
    height: 41px;
    line-height: 1.39;
    margin-top: 54px;
    padding: 0 17px;
    transition: opacity .4s;
    will-change: opacity;
    &:focus,
    &:hover {
      opacity: .7
    }
  }
  label.error {
    @include stack();
    background: $cor-vermelho-claro;
    box-sizing: border-box;
    clear: both;
    color: $cor-branco;
    float: left;
    font-size: em(12);
    font-weight: bold;
    margin-top: 3px;
    padding: 5px 10px;
  }
  label.error {
    ~ [type=file] {
      margin-top: 8px;
    }
  }
  [name="nome"] {
    margin-top: 0;
  }
}
