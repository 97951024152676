.seguradoras-lista {
  @include stack();
  display: inline-block;
  margin-left: -13px;
  width: calc(100% + 13px);
}
.seguradoras-item {
  @include column(1/5, $gutter: 2.04, $cycle: 5);
  align-items: center;
  border: solid 1px $cor-cinza-claro;
  box-sizing: border-box;
  display: flex;
  height: 116px;
  justify-content: center;
  &:nth-child(5) ~ & {
    margin-top: 23px;
  }
  @media (max-width: 767px) {
    @include column(1/4, $gutter: 3, $cycle: 4);
    &:nth-child(4) ~ & {
      margin-top: 23px;
    }
  }
  @media (max-width: 540px) {
    @include column(1/3, $gutter: 4, $cycle: 3);
    &:nth-child(3) ~ & {
      margin-top: 23px;
    }
  }
  @media (max-width: 400px) {
    @include column(1/2, $gutter: 5, $cycle: 2);
    &:nth-child(2) ~ & {
      margin-top: 23px;
    }
  }
}
.seguradoras-logo {
  height: auto;
  max-height: calc(100% - 20px);
  max-width: calc(100% - 20px);
  width: auto;
}
