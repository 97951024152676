/* ERRO 404 */

#notfound {
  width: 50%;
  margin: 70px auto;
  font: 14px/1.8 "Trebuchet MS", Arial, Helvetica, sans-serif;
  background: url(../img/erro404-exclamacao.png) 0 bottom no-repeat;
  padding: 0 0 0 160px;
  min-height: 128px;
  @media (max-width: 767px) {
    background: none;
    padding: 0;
  }
  @media (max-width: 420px) {
    margin: 0;
    width: 100%;
  }
}

#notfound h2 {
  color: #900;
  font-size: 22px;
  font-weight: 700
}

#notfound h3 {
  color: #333;
  margin: 0 0 10px 0;
  font-size: 15px;
  font-weight: 700
}

