/* Título */

.titulo {
  @include center(980px)
  border-bottom: solid 2px $cor-cinza-claro-2;
  color: $cor-preto;
  font-size: em(30);
  line-height: 1.27;
  margin-top: 66px;
  padding-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
  span {
    color: $cor-amarelo-3;
    font-weight: 600;
  }
}
/* Caixa interna */

.conteudoInterna {
  @include center(980px);
  box-sizing: border-box;
  padding: 64px 0 137px 13px;
  &:empty {
    display: none;
  }
  @media (max-width: 1020px) {
    @include cf();
  }
}

/* Área de editor */

.conteudoEditor {
  box-sizing: border-box;
  color: $cor-cinza-medio-4;
  font-size: em(16);
  line-height: 1.56;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $cor-marrom;
    font-weight: 700;
    margin-top: 1.5em;
    text-transform: uppercase;
  }
  h2 {
    font-size: 1.38em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }
  h6 {
    font-size: 1em;
  }



  .foto {
    display: inline-block;
    float: left;
    margin: 0 2.07% 20px 0;
    position: relative;
    width: 39.30%;
    &::before {
      content: '';
      display: block;
      padding-top: 57.27%;
    }
    + * {
      margin-top: 0;
    }
    a {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
    }
    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
    @media (max-width: 480px) {
      float: none;
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100%;
    }
  }

  &.fotoDireita .foto {
    float: right;
    margin: 0 0 20px 2.07%;
  }

  .foto span {
    background: rgba($cor-preto, .6);
    bottom: 0;
    color: $cor-branco;
    display: none;
    font-size: 1em;
    left: 0;
    line-height: 2;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  ul.fotos {
    clear: both;
    display: inline-block;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  ul.fotos li {
    @include column(1/4);
  }

  &.fotosBaixo {
    padding: 0 0 170px 0;
    position: relative
  }

  &.fotosBaixo ul.fotos {
    position: absolute;
    bottom: 0
  }

}
