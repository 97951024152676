/* PAGINACAO */

.paginacao {
  @include stack();
  display: inline-block;
  margin: 49px 0 0 -6px;
  li {
    float: left;
    margin-right: 7px;
    @media (max-width: 1120px) {
      margin-right: 15px;
    }
  }
  a {
    background: $cor-cinza-claro-3;
    border-radius: 50%;
    display: inline-block;
    height: 11px;
    text-decoration: none;
    transition: opacity .4s;
    width: 11px;
    will-change: opacity;
    &:focus,
    &:hover {
      opacity: .8;
    }
    &.atual {
      background: $cor-preto;
    }
    @media (max-width: 1120px) {
      height: 20px;
      width: 20px;
    }
  }
  &.paginacao-2 {
    width: calc(100% + 13px);
    margin: 27px 0 0 -3px;
    li {
      margin-right: 13px;
      @media (max-width: 1120px) {
        margin-right: 20px;
      }
    }
    a {
      background: $cor-preto;
      height: 17px;
      width: 17px;
      &.atual {
        background: $cor-amarelo-2;
      }
      @media (max-width: 1120px) {
        height: 24px;
        width: 24px;
      }
    }
  }
}
